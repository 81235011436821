import React, { useCallback, useEffect, useState, useReducer, useRef, useMemo } from "react";
import { ACCURACY_THRESHOLD, createPosition, getGeolocationFn, getGeolocationMethodHTML5, } from "utils/position";
import { DirectionsPane } from "main/components/directions/DirectionsPane";
import { reducer } from "main/components/directions/reducer";
import { clearDirections, updateDirectionsInfo, updateOrigin, updateStore, updateTravelMode, } from "main/components/directions/actions";
import { TRAVEL_MODE } from "main/types/directions";
import { getCurrentDevice } from "utils/device";
import { Device } from "main/types/device";
import { analyticsHandler, directionsRequestHandler, infoWindowClickHandler, scrollingHandler, storeCardClickHandler, } from "core/events";
import { getConsentData, LOCAL_STORAGE_USER_POSITION_CONSENT, } from "main/utils/local-storage";
import ConsentModal from "main/components/consent-modal/ConsentModal";
import { createAutocompleteHandler, retailTuneAutocompleteHandler } from "@retailtune/utils";
import { googleAutocompleteHandler } from "@retailtune/google-maps-utils";
import { GeolocationErrors } from "main/utils/errors";
export var STORE_LOCATOR_PROPS;
(function (STORE_LOCATOR_PROPS) {
    STORE_LOCATOR_PROPS["ANCHOR_POINTS"] = "anchorPoints";
    STORE_LOCATOR_PROPS["ALL_STORES"] = "allStores";
    STORE_LOCATOR_PROPS["ALL_SERVICES"] = "allServices";
    STORE_LOCATOR_PROPS["CHILDREN"] = "children";
    STORE_LOCATOR_PROPS["CONFIGURATION"] = "configuration";
    STORE_LOCATOR_PROPS["DEFAULT_POSITION"] = "defaultPosition";
    STORE_LOCATOR_PROPS["PRESELECTED_POSITION"] = "preselectedPosition";
    STORE_LOCATOR_PROPS["PRESELECTED_SERVICES"] = "preselectedServices";
    STORE_LOCATOR_PROPS["PRESELECTED_STORE_CODE"] = "preselectedStoreCode";
})(STORE_LOCATOR_PROPS || (STORE_LOCATOR_PROPS = {}));
export default function StoreLocator(props) {
    // ref to store locator DOM node. Useful for portals
    const storeLocatorRef = useRef(null);
    // user device type
    const [currentDevice, setCurrentDevice] = useState(getCurrentDevice);
    // position information
    const [position, setPosition] = useState(() => {
        var _a;
        return ({
            defaultPosition: props.defaultPosition,
            userPosition: props.defaultPosition,
            currentPosition: (_a = props.preselectedPosition) !== null && _a !== void 0 ? _a : props.defaultPosition,
        });
    });
    // filters information
    const [filter, setFilter] = useState(() => {
        var _a;
        return ({
            services: (_a = props.preselectedServices) !== null && _a !== void 0 ? _a : [],
        });
    });
    // map zoom (update through autocomplete)
    const [mapZoom, setMapZoom] = useState(10);
    // driving directions information
    const [drivingDirections, dispatch] = useReducer(reducer, {
        userPosition: position.userPosition,
        origin: position.userPosition,
        store: props.allStores.find(s => s.storeCode === (props === null || props === void 0 ? void 0 : props.preselectedStoreCode)),
        directionsInfo: undefined,
        travelMode: TRAVEL_MODE.DRIVING,
    });
    // currently selected store
    const [selectedStore, setSelectedStore] = useState("");
    // user consent to his position usage
    const [positionConsent, setPostionConsent] = useState(() => !!getConsentData(LOCAL_STORAGE_USER_POSITION_CONSENT));
    // visibility of the user consent modal
    const [isConsentModalVisible, setConsentModalVisible] = useState(() => !positionConsent);
    // autocomplete state
    const [autocompleteValue, setAutocompleteValue] = useState("");
    // openingTimes cache for all stores
    const [openingTimesCache, setOpeningTimesCache] = useState(new Map());
    // processing the opening time string for every store is delegated to a web worker
    useEffect(() => {
        const worker = new Worker("/data/time.js");
        worker.postMessage({
            stores: props.allStores,
            serverTime: props.configuration.time,
            openingTimes: props.configuration.translations.openingTimes,
        });
        worker.onmessage = function (e) {
            // console.log("web worker success! ", e.data);
            setOpeningTimesCache(e.data);
        };
        worker.onerror = function (err) {
            console.error("web worker ERROR! ", err.message);
        };
    }, []);
    // listen to screen resize to update currentDevice information
    // and scroll detection on window
    useEffect(() => {
        const app = document.querySelector(".rt-app");
        function handleDeviceChange() {
            const deviceAfterResize = getCurrentDevice();
            setCurrentDevice(prev => {
                // console.log(deviceAfterResize);
                if (prev !== deviceAfterResize)
                    return deviceAfterResize;
                return prev;
            });
        }
        function handleScroll() {
            scrollingHandler.dispatch({
                scrollingItem: app,
                scrollingOffset: app.scrollTop,
            });
        }
        window.addEventListener("resize", handleDeviceChange);
        app.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("resize", handleDeviceChange);
            app.removeEventListener("scroll", handleScroll);
        };
    }, []);
    // updates current selected store from both map and list
    useEffect(() => {
        function infoWindowClickListener(e) {
            // console.log("info window click", event.detail);
            setSelectedStore(e.detail.activeStore);
        }
        function storeCardClickListener(e) {
            // console.log("store card click", event.detail);
            setSelectedStore(e.detail.activeStore);
        }
        infoWindowClickHandler.addListener(infoWindowClickListener);
        storeCardClickHandler.addListener(storeCardClickListener);
        return () => {
            infoWindowClickHandler.removeListener(infoWindowClickListener);
            storeCardClickHandler.removeListener(storeCardClickListener);
        };
    }, []);
    // tries to geolocate user. Keeps the consent modal visibility updated
    useEffect(() => useUserPosition(), [positionConsent]);
    // updates driving directions origin to user position whenever it is changed
    useEffect(() => {
        setOrigin(position.userPosition);
    }, [position.userPosition]);
    // listen to custom event dispatched when driving directions are fetched by a map component
    useEffect(() => {
        function directionsRequestListener(event) {
            if (currentDevice !== Device.DESKTOP) {
                return window.open(event.detail.destinationStore.storeLink, "_blank");
            }
            event.detail.onDirectionsReady();
        }
        directionsRequestHandler.addListener(directionsRequestListener);
        if (!(props === null || props === void 0 ? void 0 : props.preselectedStoreCode)) {
            resetDirections();
        }
        return () => directionsRequestHandler.removeListener(directionsRequestListener);
    }, [currentDevice]);
    // creates the geolocation function based on various geolocation methods
    const geolocateUser = useCallback(getGeolocationFn(props.defaultPosition, props.configuration.translations.warnings.geolocation.cannotGeolocateUser, getGeolocationMethodHTML5(ACCURACY_THRESHOLD)), []);
    // creates the function used across the application to set the current position to the user's one
    const useUserPosition = useCallback(() => {
        if (!positionConsent)
            return setConsentModalVisible(true);
        geolocateUser().then(userPosition => {
            setPosition(prev => ({ ...prev, userPosition, currentPosition: userPosition }));
            setMapZoom(10);
        });
    }, [positionConsent]);
    // below a collection of methods used across the application to update the driving directions state
    const resetDirections = useCallback(() => {
        dispatch(clearDirections());
    }, []);
    const setOrigin = useCallback((origin) => {
        dispatch(updateOrigin(origin));
    }, []);
    const setStore = useCallback((store) => {
        dispatch(updateStore(store));
    }, []);
    const setDirectionsInfo = useCallback((directionsInfo) => {
        dispatch(updateDirectionsInfo(directionsInfo));
    }, []);
    const setTravelMode = useCallback((travelMode) => {
        dispatch(updateTravelMode(travelMode));
    }, []);
    const countries = useMemo(() => {
        return Array.from(new Set(props.allStores.map(s => s.country.tagISO31661Alpha2)));
    }, [props.allStores]);
    const searchHandler = useCallback(createAutocompleteHandler(retailTuneAutocompleteHandler(props.configuration.api.retailtune, {
        language: props.configuration.language,
        countries,
        layers: ["venue", "street", "locality", "country"]
    }), googleAutocompleteHandler()), [countries]);
    const predictionClickHandler = useCallback((prediction) => {
        if (!(prediction.latitude && prediction.longitude))
            throw new Error(GeolocationErrors.BAD_PREDICTION);
        if (drivingDirections.store) {
            resetDirections();
        }
        const currentPosition = createPosition({
            latitude: prediction.latitude,
            longitude: prediction.longitude,
        });
        let zoom = 10;
        switch (prediction.layer) {
            case 'country':
                zoom = 6;
                break;
            case 'region':
                zoom = 9;
                break;
            case 'locality':
                zoom = 13;
                break;
            case 'address':
            case 'street':
            case 'venue':
                zoom = 17;
                break;
        }
        // console.log(`layer: ${prediction.layer} - zoom: ${zoom}`);
        setMapZoom(zoom);
        setPosition(prev => ({ ...prev, currentPosition }));
        analyticsHandler.dispatch({
            type: "free_search",
            subtype: "geolocation",
            payload: { search: prediction.description },
        });
    }, [drivingDirections.store]);
    const placeholder = props.configuration.translations.header.autocomplete.placeholder;
    const searchIconPath = props.configuration.autocomplete.searchIconPath;
    const clearIconPath = props.configuration.autocomplete.clearIconPath;
    return (React.createElement("div", { ref: storeLocatorRef, className: "rt-store-locator" },
        React.createElement(props.StoreLocatorVariant, { mapZoom,
            setMapZoom,
            autocomplete: {
                value: autocompleteValue,
                setValue: setAutocompleteValue,
                searchHandler,
                predictionClickHandler,
                placeholder,
                zeroResultsMessage: props.configuration.translations.autocomplete.zeroResultsMessage,
                clearIcon: {
                    path: clearIconPath,
                    position: "left",
                },
                searchIcon: {
                    path: searchIconPath,
                    position: "right",
                },
            },
            configuration: props.configuration,
            currentDevice,
            storeLocatorRef,
            anchorPoints: { ...props.anchorPoints, storeLocator: storeLocatorRef.current },
            allServices: props.allServices,
            allStores: props.allStores,
            children: props.children,
            openingTimesCache,
            selectedStore,
            setSelectedStore,
            position: {
                geolocateUser,
                useUserPosition,
                position,
                setPosition,
            },
            directions: {
                origin: drivingDirections.origin,
                destinationStore: drivingDirections.store,
                travelMode: drivingDirections.travelMode,
                resetDirections,
                setStore,
                setDirectionsInfo,
                setTravelMode,
            },
            filters: {
                filter,
                setFilter,
            } }),
        isConsentModalVisible && (React.createElement(ConsentModal, { translations: props.configuration.translations.consentModal, setUserConsent: setPostionConsent, setModalVisibility: setConsentModalVisible })),
        currentDevice === Device.DESKTOP && (React.createElement(DirectionsPane, { allStores: props.allStores, directions: drivingDirections, clearDirections: resetDirections, setOrigin: setOrigin, setTravelMode: setTravelMode, language: props.configuration.language, translations: props.configuration.translations.directionsPane, retailtuneKey: props.configuration.api.retailtune }))));
}
